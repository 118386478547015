import axios from 'axios'
import { onFormatDate } from 'modules/shared/utilities/utility'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import {
  IAuditStatementGenerateRequest,
  IGenerateAuditStatementRes,
  IIAuditStatementClientsApiRes
} from '../store/types'
import {
  IAuditStatementApiReq,
  IAuditStatementApiResp,
  IAuditStatementLogsRes
} from './types'

const fetchAuditStatementsClientsService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/CheckDeposit/GetNonRTCClients`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IIAuditStatementClientsApiRes>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const generateAuditStatementService = (
  req: IAuditStatementGenerateRequest,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/CheckDeposit/GenerateAuditStatement`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .post<IGenerateAuditStatementRes>(url, req, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const fetchAuditStatementService = (
  req: IAuditStatementApiReq,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/CheckDeposit/GetAuditStatement?clientId=${
    req?.clientId
  }&fromDate=${onFormatDate(req?.fromDate)}&toDate=${onFormatDate(req?.toDate)}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IAuditStatementApiResp>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}

const fetchAuditStatementLogsService = (options: IApiOptions) => {
  const url = `${options.apiRoot}/cdm/CheckDeposit/GetAuditStatementLogs`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IAuditStatementLogsRes>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
const fetchAuditStatementRecipientService = (
  partyUniqueId: string,
  options: IApiOptions
) => {
  const url = `${options.apiRoot}/cdm/CheckDeposit/GetAuditStatementRecipient?partyUniqueId=${partyUniqueId}`
  const headers = {
    Authorization: `Bearer ${options.accessToken}`
  }
  return axios
    .get<IAuditStatementLogsRes>(url, {
      headers,
      cancelToken: options.cancelToken
    })
    .then((res) => res.data)
}
export {
  fetchAuditStatementsClientsService,
  generateAuditStatementService,
  fetchAuditStatementService,
  fetchAuditStatementLogsService,
  fetchAuditStatementRecipientService
}
