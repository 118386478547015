import { b64toBlob } from 'modules/shared/utilities/utility'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { getRockefellerApiOptions } from 'store/shared/sagas'
import { call, delay, put, takeLatest } from 'typed-redux-saga'
import {
  fetchAuditStatementLogsService,
  fetchAuditStatementRecipientService,
  fetchAuditStatementsClientsService,
  fetchAuditStatementService,
  generateAuditStatementService
} from '../api/AuditStatementService'
import {
  generateAuditStatementActions,
  fetchAuditStatementClientsActions,
  auditStatementActions,
  fetchAuditStatementLogsActions,
  fetchAuditStatementRecipientByPartyUniqueIdActions
} from './actions'

function* handleFetchAuditStatementsClients() {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchAuditStatementsClientsService(options)
    )
    if (response) {
      yield put(fetchAuditStatementClientsActions.success(response))
    } else {
      yield put(
        fetchAuditStatementClientsActions.failure(
          new Error('unable to fetch clients list')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchAuditStatementClientsActions.failure(e))
  }
}

function* handleGenerateAuditStatement(
  action: ReturnType<typeof generateAuditStatementActions.request>
) {
  const options = yield* call(getRockefellerApiOptions)

  try {
    const data = yield* call(() =>
      generateAuditStatementService(action.payload, options)
    )
    if (data.success) {
      yield put(generateAuditStatementActions.success())
      if (data?.data) {
        const blob = b64toBlob(data.data[0])
        const fileURL = URL.createObjectURL(blob)
        window.open(fileURL, 'DocumentWindow')
      }
    }
  } catch (e: any) {
    yield put(generateAuditStatementActions.failure(e))
  }
}
function* handleFetchAuditStatement(
  action: ReturnType<typeof auditStatementActions.request>
) {
  try {
    yield delay(300)
    const options = yield* call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchAuditStatementService(action.payload, options)
    )
    if (response.success) {
      yield put(auditStatementActions.success(response))
    } else {
      yield put(
        auditStatementActions.failure(new Error('Unable to generate PDF'))
      )
    }
  } catch (e: any) {
    yield put(auditStatementActions.failure(e))
  }
}

function* handleFetchAuditStatementLogs() {
  try {
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() => fetchAuditStatementLogsService(options))
    if (response) {
      yield put(fetchAuditStatementLogsActions.success(response))
    } else {
      yield put(
        fetchAuditStatementLogsActions.failure(
          new Error('unable to fetch logs list')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchAuditStatementLogsActions.failure(e))
  }
}

function* handleAuditStatementRecipientByPartyUniqueId(
  action: ReturnType<
    typeof fetchAuditStatementRecipientByPartyUniqueIdActions.request
  >
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchAuditStatementRecipientService(action.payload, options)
    )
    if (response.success) {
      yield put(
        fetchAuditStatementRecipientByPartyUniqueIdActions.success(response)
      )
    } else {
      yield put(
        fetchAuditStatementRecipientByPartyUniqueIdActions.failure(
          new Error('Unable to get Audit Statement by Party Unique Id')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchAuditStatementRecipientByPartyUniqueIdActions.failure(e))
  }
}

export const AuditStatementsagas = [
  () =>
    takeLatest(
      fetchAuditStatementClientsActions.request,
      handleFetchAuditStatementsClients
    ),
  () =>
    takeLatest(
      generateAuditStatementActions.request,
      handleGenerateAuditStatement
    ),
  () => takeLatest(auditStatementActions.request, handleFetchAuditStatement),
  () =>
    takeLatest(
      fetchAuditStatementLogsActions.request,
      handleFetchAuditStatementLogs
    ),
  () =>
    takeLatest(
      fetchAuditStatementRecipientByPartyUniqueIdActions.request,
      handleAuditStatementRecipientByPartyUniqueId
    )
]
