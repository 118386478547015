import { ActionType, createAction } from 'typesafe-actions'
import {
  IAuditStatementApiReq,
  IAuditStatementApiResp,
  IAuditStatementLogsRes,
  IAuditStatementRecipientByPartyUniqueIdApiRes
} from '../api/types'
import {
  IIAuditStatementClientsApiRes,
  IAuditStatementGenerateRequest
} from './types'

export const FETCH_AUDITSTATEMENT_CLIENTS_REQUEST = `@modules/@AUDITREQUEST/FETCH_AUDITSTATEMENT_CLIENTS_REQUEST`
export const FETCH_AUDITSTATEMENT_CLIENTS_SUCCESS = `@modules/@AUDITREQUEST/FETCH_AUDITSTATEMENT_CLIENTS_SUCCESS`
export const FETCH_AUDITSTATEMENT_CLIENTS_FAILURE = `@modules/@AUDITREQUEST/FETCH_AUDITSTATEMENT_CLIENTS_FAILURE`
export const FETCH_AUDITSTATEMENT_CLIENTS_CLEAR = `@modules/@AUDITREQUEST/FETCH_AUDITSTATEMENT_CLIENTS_CLEAR`

export const fetchAuditStatementClientsActions = {
  request: createAction(FETCH_AUDITSTATEMENT_CLIENTS_REQUEST)(),
  success: createAction(
    FETCH_AUDITSTATEMENT_CLIENTS_SUCCESS
  )<IIAuditStatementClientsApiRes>(),
  failure: createAction(FETCH_AUDITSTATEMENT_CLIENTS_FAILURE)<Error>(),
  clear: createAction(FETCH_AUDITSTATEMENT_CLIENTS_CLEAR)<undefined>()
}

export const GENERATE_AUDITSTATEMENT_REQUEST = `@modules/@AUDITREQUEST/GENERATE_AUDITSTATEMENT_REQUEST`
export const GENERATE_AUDITSTATEMENT_SUCCESS = `@modules/@AUDITREQUEST/GENERATE_AUDITSTATEMENT_SUCCESS`
export const GENERATE_AUDITSTATEMENT_FAILURE = `@modules/@AUDITREQUEST/GENERATE_AUDITSTATEMENT_FAILURE`
export const GENERATE_AUDITSTATEMENT_CLEAR = `@modules/@AUDITREQUEST/GENERATE_AUDITSTATEMENT_CLEAR`

export const generateAuditStatementActions = {
  request: createAction(
    GENERATE_AUDITSTATEMENT_REQUEST
  )<IAuditStatementGenerateRequest>(),
  success: createAction(GENERATE_AUDITSTATEMENT_SUCCESS)(),
  failure: createAction(GENERATE_AUDITSTATEMENT_FAILURE)<Error>(),
  clear: createAction(GENERATE_AUDITSTATEMENT_CLEAR)<undefined>()
}
export const AUDIT_STATEMENT_REQUEST = `@modules/@AUDITREQUEST/AUDIT_STATEMENT_REQUEST`
export const AUDIT_STATEMENT_SUCCESS = `@modules/@AUDITREQUEST/AUDIT_STATEMENT_SUCCESS`
export const AUDIT_STATEMENT_FAILURE = `@modules/@AUDITREQUEST/AUDIT_STATEMENT_FAILURE`

export const auditStatementActions = {
  request: createAction(AUDIT_STATEMENT_REQUEST)<IAuditStatementApiReq>(),
  success: createAction(AUDIT_STATEMENT_SUCCESS)<IAuditStatementApiResp>(),
  failure: createAction(AUDIT_STATEMENT_FAILURE)<Error>()
}

export const FETCH_AUDITSTATEMENT_LOGS_REQUEST = `@modules/@AUDITREQUEST/FETCH_AUDITSTATEMENT_LOGS_REQUEST`
export const FETCH_AUDITSTATEMENT_LOGS_SUCCESS = `@modules/@AUDITREQUEST/FETCH_AUDITSTATEMENT_LOGS_SUCCESS`
export const FETCH_AUDITSTATEMENT_LOGS_FAILURE = `@modules/@AUDITREQUEST/FETCH_AUDITSTATEMENT_LOGS_FAILURE`
export const FETCH_AUDITSTATEMENT_LOGS_CLEAR = `@modules/@AUDITREQUEST/FETCH_AUDITSTATEMENT_LOGS_CLEAR`

export const fetchAuditStatementLogsActions = {
  request: createAction(FETCH_AUDITSTATEMENT_LOGS_REQUEST)(),
  success: createAction(
    FETCH_AUDITSTATEMENT_LOGS_SUCCESS
  )<IAuditStatementLogsRes>(),
  failure: createAction(FETCH_AUDITSTATEMENT_LOGS_FAILURE)<Error>(),
  clear: createAction(FETCH_AUDITSTATEMENT_LOGS_CLEAR)<undefined>()
}

export const FETCH_AUDITSTATEMENT_RECIPIENT_REQUEST = `@modules/@AUDITREQUEST/FETCH_AUDITSTATEMENT_RECIPIENT_REQUEST`
export const FETCH_AUDITSTATEMENT_RECIPIENT_SUCCESS = `@modules/@AUDITREQUEST/FETCH_AUDITSTATEMENT_RECIPIENT_SUCCESS`
export const FETCH_AUDITSTATEMENT_RECIPIENT_FAILURE = `@modules/@AUDITREQUEST/FETCH_AUDITSTATEMENT_RECIPIENT_FAILURE`
export const FETCH_AUDITSTATEMENT_RECIPIENT_CLEAR = `@modules/@AUDITREQUEST/FETCH_AUDITSTATEMENT_RECIPIENT_CLEAR`

export const fetchAuditStatementRecipientByPartyUniqueIdActions = {
  request: createAction(FETCH_AUDITSTATEMENT_RECIPIENT_REQUEST)<string>(),
  success: createAction(
    FETCH_AUDITSTATEMENT_RECIPIENT_SUCCESS
  )<IAuditStatementRecipientByPartyUniqueIdApiRes>(),
  failure: createAction(FETCH_AUDITSTATEMENT_RECIPIENT_FAILURE)<Error>(),
  clear: createAction(FETCH_AUDITSTATEMENT_RECIPIENT_CLEAR)<undefined>()
}

export type fetchAuditStatementClientsActionType = ActionType<
  typeof fetchAuditStatementClientsActions
>
export type generateAuditStatementActionsType = ActionType<
  typeof generateAuditStatementActions
>
export type auditStatementActionsType = ActionType<typeof auditStatementActions>

export type fetchAuditStatementLogsActionsType = ActionType<
  typeof fetchAuditStatementLogsActions
>
export type fetchAuditStatementRecipientByPartyUniqueIdActionsType = ActionType<
  typeof fetchAuditStatementRecipientByPartyUniqueIdActions
>

export type AuditStatementActionTypes =
  | fetchAuditStatementClientsActionType
  | generateAuditStatementActionsType
  | auditStatementActionsType
  | fetchAuditStatementLogsActionsType
  | fetchAuditStatementRecipientByPartyUniqueIdActionsType
